@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
#globalGetAppModalContent {
  margin-bottom: 12px;

  .validation-error {
    color: $red-primary;
    padding: rem-calc(10 0 10 30);
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: rem-calc(20);
  }

  .spa-btn {
    margin-top: 26px;
  }

    .title {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(18);
    color: $brown-tertiary;
  }

  .content {

    &.with-icon {
      background-position: left center;
      background-repeat: no-repeat;
      padding: rem-calc(10 0 10 60);
      background-size: rem-calc(50);

      &.success-icon {
        padding: rem-calc(14 0 14 40);
        background-position: left top;
        background-size: rem-calc(28);
      }
    }

    #iphone-get-app,
    #android-get-app,
    #ipad-get-app,
    #windows10-get-app,
    #other-get-app {
      margin-top: 30px;
    }

    section {
      display: none;

      &.open {
        display: block;
      }

      &#other {
        .disclaimer {
          font-size: 14px;
        }
      }

      h5 {
        color: $red-primary;
        font-size: rem-calc(18);
        margin-bottom: rem-calc(3);
      }

      .app-store-image {
        width: 135px;
      }

      .disclaimer {
        color: $dark-gray;
        font-size: rem-calc(12);
        margin-bottom: rem-calc(18);
        margin-top: rem-calc(12);

        &.contact-section {
          margin: 5px 0 0 0;
        }

        &.trademark-disclosure {
          color: $black;
          font-size: 10px;
          margin-bottom: 0;
        }
      }

      .primary-action {
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(20);
      }

      .secondary-action {
        margin-bottom: rem-calc(30);
      }

      @media #{$small-only} {
        .spa-input-btn-group {
          .small-12 {
            .spa-btn--medium {
              margin: 0;
            }
            .spa-error-form-label--required,
            .spa-error-form-label--validation {
              ~ .spa-input-error-message {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }

  .spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #0073cf;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    float: right;
    margin-top: 4px;
    margin-left: 4px;
  }

  @keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }
}
